import { Box, Container, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import {
  Suspense,
  useEffect,
  useCallback,
  useMemo,
  useState,
  ReactElement,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ResultItemCard } from '@app/components/Product/ResultItemCard';
import {
  Conditions,
  SearchCondition,
} from '@app/components/Search/SearchCondition';
import { SearchNoResult } from '@app/components/Search/SearchNoResult';
import { SearchTypeTab } from '@app/components/Search/SearchTypeTab';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { Breadcrumb } from '@app/components/Shared/Breadcrumb';
import {
  getProductsDescription,
  HeadBlock,
} from '@app/components/Shared/HeadBlock';
import { Loading } from '@app/components/Shared/Loading';
import { ScrollThreshold } from '@app/components/Shared/ScrollThreshold';
import {
  categorySelector,
  locationsByPrefectureSelector,
  searchConditionState,
  searchResultProductsState,
} from '@app/domain/catalog';
import { getProducts } from '@app/domain/network-actions';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import { theme } from '@app/theme';
import { CategoryName } from '@app/types/catalog';
import { LoadableState } from '@app/types/common';
import { getSearchResultUrl } from '@app/utils/catalog';

const breadcrumbItems = [
  { label: 'トップページ', link: '/' },
  { label: '検索結果' },
];

export function Index(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const setSnackbar = useSetSnackbar();
  const [conditionState, setConditionState] =
    useRecoilState(searchConditionState);
  const [resultProducts, setResultProducts] = useRecoilState(
    searchResultProductsState
  );
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );
  const categories = useRecoilValue(categorySelector);
  const prefectures = useRecoilValue(locationsByPrefectureSelector);

  const getParamToString = (value: string | null) => {
    return value ? value.split(',') : undefined;
  };

  const searchCondition = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);

    const floorPlanRoomsString = queryParams.get('floorPlanRooms');
    const floorPlanRooms = floorPlanRoomsString
      ? floorPlanRoomsString.split(',').map((value) => parseInt(value, 10))
      : [];

    return {
      access: queryParams.get('access') || '',
      categoryId: queryParams.get('category') || '',
      floorPlanRooms,
      locationIds: getParamToString(queryParams.get('locations')) || [],
      maxBuildingArea: queryParams.get('maxBuildingArea') || '',
      maxLandArea: queryParams.get('maxLandArea') || '',
      maxPrice: queryParams.get('maxPrice') || '',
      minBuildingArea: queryParams.get('minBuildingArea') || '',
      minLandArea: queryParams.get('minLandArea') || '',
      minPrice: queryParams.get('minPrice') || '',
    };
  }, [location.search]);

  const headInfo = useMemo(() => {
    const category = categories?.find(
      (c) => c.id === searchCondition.categoryId
    )?.name;
    const prefecture =
      searchCondition.locationIds.length === 1
        ? prefectures?.find((p) => p.id === searchCondition.locationIds[0])
            ?.name
        : undefined;
    const paramLength = Array.from(
      new URLSearchParams(location.search).keys()
    ).length;

    if (!category || paramLength > 2 || (!prefecture && paramLength > 1)) {
      return {};
    }
    return {
      description: getProductsDescription(category, prefecture),
    };
  }, [categories, location.search, prefectures, searchCondition]);

  const submitSearch = useCallback(
    (data?: Conditions) => {
      const conditions = { ...searchCondition, ...data };
      setConditionState(null);
      globalThis.scrollTo(0, 0);
      navigate(getSearchResultUrl(conditions));
    },
    [navigate, searchCondition, setConditionState]
  );

  const tabName = useMemo(() => {
    if (location.pathname.includes('/exhibitions')) {
      return CategoryName.EXHIBITION;
    } else if (location.pathname.includes('/properties')) {
      return CategoryName.PROPERTY;
    }
    return CategoryName.PROPERTY;
  }, [location.pathname]);

  const fetchProducts = useCallback(
    async (nextLink?: string) => {
      if (loadable === LoadableState.LOADING) return;
      setLoadable(LoadableState.LOADING);
      try {
        const options = {
          ...searchCondition,
          categoryIds: searchCondition?.categoryId
            ? [searchCondition.categoryId]
            : [],
          expand: 'attributes,variants,images,organization,category,locations',
          nextLink,
        };

        const result = await getProducts(options);

        setConditionState(searchCondition);
        setResultProducts({
          data: nextLink
            ? [...resultProducts.data, ...result.data.value]
            : result.data.value,
          nextLink: result.data['@nextLink'],
          total: result.data.total,
        });
        setLoadable(LoadableState.HAS_VALUE);
      } catch (error) {
        setLoadable(LoadableState.HAS_ERROR);
        setSnackbar(true, '求人の取得に失敗しました');
      }
    },
    [
      loadable,
      searchCondition,
      resultProducts.data,
      setConditionState,
      setSnackbar,
      setResultProducts,
    ]
  );

  useEffect(() => {
    if (!conditionState) {
      setResultProducts({
        data: [],
        nextLink: '',
        scrollY: 0,
        total: 0,
      });
      void fetchProducts();
    } else {
      globalThis.scrollTo(0, resultProducts?.scrollY || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCondition]);

  const handleScrollThresholdReached = async () => {
    if (_.isEmpty(resultProducts.nextLink)) {
      return;
    }
    await fetchProducts(resultProducts.nextLink);
  };

  const onChangeTab = useCallback(
    (selectedTab: string) => {
      submitSearch({ ...searchCondition });
    },
    [searchCondition, submitSearch]
  );

  const handleClickProduct = useCallback(
    (id: string) => {
      setResultProducts({ ...resultProducts, scrollY: globalThis.pageYOffset });
      navigate(`/properties/${id}`);
    },
    [navigate, resultProducts, setResultProducts]
  );

  return (
    <>
      <HeadBlock {...headInfo} />
      <Box data-e2e="searchResult-body">
        <Stack spacing={1} sx={{ bgcolor: 'secondary.main' }}>
          <Breadcrumb items={breadcrumbItems} />
          <Box>
            <SearchTypeTab
              items={categories}
              value={tabName}
              onChangeTab={onChangeTab}
            />
            <Stack direction="row" justifyContent="space-between">
              <Box>
                <Suspense fallback={<Loading />}>
                  <SearchCondition
                    condition={searchCondition}
                    onSearch={submitSearch}
                    showPropertySearch={true}
                  />
                </Suspense>
              </Box>
              <Box flex={2}>
                <Container sx={{ py: 2 }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                      検索結果
                    </Typography>
                    <Typography color={theme.palette.neutral.greyDark}>
                      {resultProducts.total}件
                    </Typography>
                  </Stack>
                </Container>
                {loadable !== LoadableState.LOADING &&
                  resultProducts.data.length === 0 && <SearchNoResult />}
                <Stack
                  alignItems="center"
                  spacing={1}
                  sx={{
                    bgcolor: 'secondary.main',
                    mb: 10,
                  }}
                >
                  {loadable === LoadableState.LOADING && (
                    <Loading title={`検索結果(${resultProducts.total})`} />
                  )}
                  {resultProducts.data.map((product, index) => (
                    <ResultItemCard
                      key={product.id + index}
                      product={product}
                      onClick={() => handleClickProduct(product.id)}
                      showPropertyDetails={true}
                    />
                  ))}
                </Stack>
                <ScrollThreshold
                  disabled={_.isEmpty(resultProducts.nextLink)}
                  thresholdReached={handleScrollThresholdReached}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
        <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
      </Box>
    </>
  );
}
