import { Box, Container, Divider, Grid } from '@mui/material';
import { ReactElement } from 'react';

import { CompanyInfo } from '@app/components/Company/CompanyInfo';
import { InquiryCards } from '@app/components/Company/Inquiry/InquiryCards';
import { OrganizationDetail } from '@app/components/Company/OrganizationDetail';
import { OrganizationStrengths } from '@app/components/Company/OrganizationStrengths';
import { ProductCardList } from '@app/components/Company/ProductCardList';
import { Loading } from '@app/components/Shared/Loading';
import { useCompanyExhibitionContext } from '@app/hooks/companyExhibitionContext';

export function OrganizationInfo(): ReactElement {
  const {
    exhibitionProducts,
    organization,
    prefectures,
    propertyProducts,
    exhibitionPrefecture,
    propertyPrefecture,
    setExhibitionPrefecture,
    productNextLink,
    setPropertyPrefecture,
    handleLoadMoreExhibitionProducts,
    handleLoadMorePropertyProducts,
    isLoadingMoreExhibitionProducts,
    isLoadingMorePropertyProducts,
    isFetchingExhibitionProducts,
    isFetchingPropertyProducts,
  } = useCompanyExhibitionContext();
  return (
    <>
      {!organization ? (
        <Loading />
      ) : (
        <Box>
          <Container
            sx={{ pb: 10, pr: { md: 4 }, pt: 3 }}
            maxWidth={false}
            disableGutters
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={9} sx={{ width: '100%' }}>
                <OrganizationDetail organization={organization} />
                <Divider sx={{ my: 5 }} />
                <OrganizationStrengths
                  data={organization.customFields?.strengths || []}
                />
                <CompanyInfo organization={organization} />
                <Grid sx={{ mb: 12, mt: 5 }}>
                  <ProductCardList
                    isFetching={isFetchingExhibitionProducts}
                    isLoadingMore={isLoadingMoreExhibitionProducts}
                    isProperty={false}
                    showLoadMore={!!productNextLink.exhibition}
                    products={exhibitionProducts || []}
                    initialVisibleItems={6}
                    prefectures={prefectures || []}
                    selectedPrefecture={exhibitionPrefecture}
                    setPrefecture={setExhibitionPrefecture}
                    handleLoadMore={handleLoadMoreExhibitionProducts}
                  />
                  <ProductCardList
                    isFetching={isFetchingPropertyProducts}
                    isLoadingMore={isLoadingMorePropertyProducts}
                    isProperty={true}
                    products={propertyProducts || []}
                    initialVisibleItems={6}
                    prefectures={prefectures || []}
                    selectedPrefecture={propertyPrefecture}
                    setPrefecture={setPropertyPrefecture}
                    handleLoadMore={handleLoadMorePropertyProducts}
                    showLoadMore={!!productNextLink.property}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sx={{ display: 'flex', justifyContent: 'center', mt: 7 }}
              >
                <InquiryCards />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
}
