import { Box, Typography, Paper } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getOrganizationProducts } from '@app/adapter/catalog-service';
import { getOrganizationsByParentIds } from '@app/adapter/organization-service';
import { imageAssets } from '@app/theme';
import { Product } from '@app/types/catalog';
import { getEllipsisStyle } from '@app/utils/format';

interface ShowroomListProps {
  organizationId: string;
}

export function ShowroomList({ organizationId }: ShowroomListProps) {
  const [showrooms, setShowrooms] = useState<Product[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  const fetchProducts = useCallback(async () => {
    const queryParams = new URLSearchParams(location.search);
    const categoryId = queryParams.get('category') || undefined;

    try {
      // 企業に紐づく子Organization(窓口)を取得
      const organizationResponse = await getOrganizationsByParentIds([
        organizationId,
      ]);
      const childOrganizationIds = organizationResponse.data.value.map(
        (org: { id: string }) => org.id
      );

      if (childOrganizationIds.length === 0) {
        return;
      }

      const response = await getOrganizationProducts(
        childOrganizationIds.join(','),
        categoryId
      );
      setShowrooms(response.data.value);
    } catch (error) {
      console.error('Failed to fetch organization products', error);
    }
  }, [organizationId, location.search]);

  useEffect(() => {
    void fetchProducts();
  }, [fetchProducts]);

  const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleShowroomClick = (event: React.MouseEvent, showroomId: string) => {
    event.stopPropagation();
    navigate(`/exhibitions/${showroomId}`);
  };

  if (showrooms.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        maxWidth: '980px',
        minWidth: '680px',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
        住宅展示場一覧
      </Typography>
      <Box
        sx={{ display: 'flex', overflowX: 'auto', width: '100%' }}
        onWheel={handleScroll}
      >
        {showrooms.map((showroom) => (
          <Box
            key={showroom.id}
            sx={{ cursor: 'pointer', flex: '0 0 auto', width: '200px' }}
            onClick={(event) => handleShowroomClick(event, showroom.id)}
          >
            <Paper elevation={0} sx={{ height: '100%', padding: 2 }}>
              <img
                src={showroom.images[0]?.url || imageAssets.productDefault}
                alt={showroom.name}
                style={{
                  borderRadius: '4px',
                  height: '120px',
                  objectFit: 'cover',
                  width: '100%',
                }}
              />
              <Typography sx={{ mb: 1, mt: 1, ...getEllipsisStyle(2) }}>
                {showroom.name}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={getEllipsisStyle(2)}
              >
                {showroom.addressLine1}
                {showroom.addressLine2}
                {showroom.addressLine3}
                {showroom.customFields.addressLine4}
              </Typography>
            </Paper>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
