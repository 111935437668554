import { Card, Stack, Typography, Divider } from '@mui/material';
import { ReactElement } from 'react';

import { ImageCarousel } from '@app/components/Product/CarouselItem';
import { ShowroomList } from '@app/components/Product/ShowroomList';
import { OrganizationStrengthsSection } from '@app/components/Product/StrengthsSection';
import { imageAssets, theme } from '@app/theme';
import { Organization } from '@app/types/organization';

interface ResultOrganizationCardProps {
  onClick?: () => void;
  organization: Organization;
}

export function ResultOrganizationCard({
  onClick,
  organization,
}: ResultOrganizationCardProps): ReactElement {
  return (
    <Card
      sx={{
        borderRadius: 0,
        cursor: onClick ? 'pointer' : 'default',
        margin: '0 auto',
        maxWidth: '1200px',
        mb: 6,
        width: '100%',
      }}
      data-e2e="searchResult-listItem"
      onClick={onClick}
    >
      <Stack spacing={2} sx={{ p: 2 }}>
        <Typography
          variant="h6"
          color={theme.palette.neutral.blueLight}
          sx={{ textDecoration: 'underline' }}
        >
          {organization.name}
        </Typography>

        <ImageCarousel
          images={
            organization?.customFields?.mainImages
              ?.map((img) => img.url)
              .filter((url): url is string => !!url) || [
              imageAssets.productDefault,
              imageAssets.productDefault,
              imageAssets.productDefault,
            ]
          }
          displayMode="carousel"
          carouselMaxWidth="980px"
          carouselMinWidth="680px"
        />

        <OrganizationStrengthsSection
          features={organization.customFields?.features || []}
          strengthsTitle={organization.customFields?.mainCatch || ''}
          strengthsDescription={organization.customFields?.subCatch || ''}
          showReadMore={true}
        />

        <Divider sx={{ mt: 2 }} />

        <ShowroomList organizationId={organization.id} />
      </Stack>
    </Card>
  );
}
