import { Button, Stack } from '@mui/material';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { CompanyFormFields } from '@app/components/Company/Inquiry/CompanyFormFields';
import { LoadingSpinner } from '@app/components/Shared/LoadingSpinner';
import { InquiryStepTwoState } from '@app/domain/order';
import { theme } from '@app/theme';
import { CategoryType, Product } from '@app/types/catalog';
import { InquiryStepTwoFormData } from '@app/types/order';
import { Organization } from '@app/types/organization';

interface CompanyInquiryStep2Props {
  defaultValues?: InquiryStepTwoFormData;
  isLoadingVariant?: boolean;
  onBack: () => void;
  onSubmit: (data: {
    productId: string;
    quantity: number;
    variantId: string;
  }) => void;
  organization?: Organization | null;
  product?: Product | null;
}

export function CompanyInquiryStep2({
  onSubmit,
  onBack,
  defaultValues,
  product,
  organization,
  isLoadingVariant,
}: CompanyInquiryStep2Props): ReactElement {
  const [stepData, setStepData] = useRecoilState(InquiryStepTwoState);
  const [searchParams] = useSearchParams();
  const category = searchParams.get('category');

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<InquiryStepTwoFormData>({
    defaultValues: defaultValues || stepData,
  });

  const requestDocQuestions = useMemo(() => {
    if (!organization || !category) return [];
    return category === CategoryType.EXHIBITION
      ? organization?.customFields?.exhibitionRequestDocQuestions ?? []
      : organization?.customFields?.propertyRequestDocQuestions ?? [];
  }, [category, organization]);

  const handleBack = useCallback(() => {
    const currentData = getValues();
    setStepData((prevData: InquiryStepTwoFormData) => ({
      ...prevData,
      ...currentData,
    }));
    onBack();
  }, [getValues, onBack, setStepData]);

  return (
    <Stack
      sx={{
        alignItems: 'center',
        width: '100%',
      }}
      spacing={4}
    >
      {isLoadingVariant ? (
        <LoadingSpinner />
      ) : (
        <Stack
          component="form"
          onSubmit={handleSubmit((data) => {
            setStepData((prevData: InquiryStepTwoFormData) => ({
              ...prevData,
              ...data,
            }));
            onSubmit({
              productId: product?.id || '',
              quantity: 1,
              variantId: product?.variants[0]?.id || '',
            });
          })}
          sx={{
            backgroundColor: theme.palette.neutral.white,
            borderRadius: '8px',
            flexDirection: 'column',
            maxWidth: '800px',
            padding: '40px',
            width: '100%',
          }}
        >
          <CompanyFormFields
            questions={requestDocQuestions}
            control={control}
            errors={errors}
            isDisabled={false}
          />
          <Stack
            direction="row"
            spacing={2}
            sx={{ mb: 12, mt: 10, width: '100%' }}
          >
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              sx={{ flex: 1, p: 2 }}
              onClick={handleBack}
            >
              戻る
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ flex: 1, p: 2 }}
            >
              次へ
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
