import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CategoryType, CategoryTypeKey } from '@app/types/catalog';

function handleButtonClick(
  navigate: ReturnType<typeof useNavigate>,
  productId: string | undefined,
  isExhibition: boolean
) {
  const path = isExhibition
    ? '/exhibitions/:productId/inquiry/create'
    : '/properties/:productId/inquiry/create';
  if (productId) {
    navigate(path.replace(':productId', productId));
  }
}

interface InquiryCardsVerticalProps {
  isExhibition: boolean;
  organizationId?: string;
  parentOrganizationId: string;
}

export function InquiryCardsVertical({
  isExhibition,
  parentOrganizationId,
}: InquiryCardsVerticalProps): ReactElement {
  const navigate = useNavigate();
  const { productId } = useParams<{ productId: string }>();

  return (
    <Box sx={{ maxWidth: 300, ml: 3, width: '100%' }}>
      <Card sx={{ border: '1px solid #ddd', borderRadius: '8px', mb: 2 }}>
        <CardContent>
          <Typography variant="body1" fontWeight={600}>
            {isExhibition ? 'この住宅展示場を見学' : 'この物件を見学'}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ mb: 2, mt: 1 }}
          >
            専門スタッフがご対応。最新の空間を体験しながら住まいづくりのプロに出会えます。
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleButtonClick(navigate, productId, isExhibition)}
          >
            見学を予約する
          </Button>
        </CardContent>

        <Divider sx={{ mt: 2, mx: 2 }} />

        <CardContent>
          <Typography variant="body1" fontWeight={600}>
            住宅カタログを無料でお届け
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ mb: 2, mt: 1 }}
          >
            いくつもの商品ラインナップを1冊の総合カタログでまとめて紹介しています。家づくりの最新情報を知るのに打ってつけです。
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => {
              if (parentOrganizationId && productId) {
                const path = isExhibition
                  ? `/companies/${parentOrganizationId}/inquiry/create?category=${CategoryType.EXHIBITION}&productId=${productId}`
                  : `/companies/${parentOrganizationId}/inquiry/create?category=${CategoryType.PROPERTY}&productId=${productId}`;
                navigate(path);
              }
            }}
          >
            資料を請求する
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

export interface InquiryCardsHorizontalProps {
  isExhibition: boolean;
  organizationId?: string;
  parentOrganizationId: string;
  type: CategoryTypeKey;
}

export function InquiryCardsHorizontal({
  type,
  isExhibition,
  parentOrganizationId,
}: InquiryCardsHorizontalProps): ReactElement {
  const navigate = useNavigate();
  const { productId } = useParams<{ productId: string }>();

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', mt: 3, width: '100%' }}
    >
      <Card
        sx={{
          border: '1px solid #ddd',
          borderRadius: '8px',
          maxWidth: '900px',
          mb: 5,
          p: 3,
          width: '100%',
        }}
      >
        <CardContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Typography variant="body1" fontWeight={600} sx={{ mb: 2 }}>
            {isExhibition ? 'この住宅展示場を見学' : 'この物件を見学'}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
            専門スタッフがご対応。最新の空間を体験しながら住まいづくりのプロに出会えます。
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {type === CategoryType.PROPERTY && (
              <Button
                variant="outlined"
                color="primary"
                sx={{ width: '200px' }}
                onClick={() => {
                  if (parentOrganizationId && productId) {
                    navigate(
                      `/companies/${parentOrganizationId}/inquiry/create?category=${CategoryType.PROPERTY}&productId=${productId}`
                    );
                  }
                }}
              >
                資料を請求する
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              sx={{ width: '200px' }}
              onClick={() =>
                handleButtonClick(navigate, productId, isExhibition)
              }
            >
              見学を予約する
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
