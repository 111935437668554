import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Button,
  Stack,
  TextField,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  getCategories,
  getOrganizationProducts,
} from '@app/adapter/catalog-service';
import { FormLabelComponent } from '@app/components/Order/FormLabelComponents';
import { LocationsWithPostalCode } from '@app/components/Shared/LocationsWithPostalCode';
import { locationsTreeSelector } from '@app/domain/catalog';
import { InquiryStepOneState } from '@app/domain/order';
import {
  InquiryStepOneForm,
  defaultInquiryStepOneFormData,
} from '@app/schemas/order';
import { theme } from '@app/theme';
import { CategoryType, Product, ProductLocationType } from '@app/types/catalog';
import { InquiryStepOneFormData } from '@app/types/order';

interface InquiryStepOneProps {
  defaultValues?: InquiryStepOneFormData;
  isExhibition?: boolean;
  onSubmit: (data: InquiryStepOneFormData) => void;
  organizationId?: string;
}

export function InquiryStepOne({
  onSubmit,
  defaultValues = defaultInquiryStepOneFormData,
  isExhibition,
  organizationId,
}: InquiryStepOneProps): ReactElement {
  const locationsTree = useRecoilValue(locationsTreeSelector);
  const { productId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [stepData, setStepData] = useRecoilState(InquiryStepOneState);
  const [prefecture, setPrefecture] = useState<{ id: string; name: string }>({
    id: defaultValues.customFields?.prefecture?.id || '',
    name: defaultValues.customFields?.prefecture?.name || '',
  });
  const [exhibition, setExhibition] = useState<{ id: string; name: string }>({
    id:
      typeof defaultValues?.items?.product?.id === 'string'
        ? defaultValues?.items?.product.id
        : '',
    name: defaultValues?.items?.product?.name || '',
  });
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [resultProducts, setResultProducts] = useState<Product[]>([]);
  const [resultLocations, setResultLocations] = useState<
    { id: string; name: string }[]
  >([]);
  const [shouldDisable, setShouldDisable] = useState<boolean>(true);

  const filteredProducts = resultProducts.filter((product) =>
    product.locationIds.includes(prefecture.id)
  );

  const initialData: InquiryStepOneFormData = {
    ...defaultValues,
    ...stepData,
  };

  const form = useForm<InquiryStepOneFormData>({
    defaultValues: initialData,
    mode: 'onChange',
    resolver: InquiryStepOneForm.resolver,
  });

  const { control, handleSubmit, getValues } = form;

  const isDisabled = isExhibition ? !prefecture || !exhibition : false;

  const fetchCategoryId = useCallback(async () => {
    try {
      const response = await getCategories();
      const propertyCategory = response.data.value.find(
        (category) => category.name === CategoryType.EXHIBITION
      );
      setCategoryId(propertyCategory ? propertyCategory.id : null);
    } catch (error) {
      console.error('Failed to fetch categories', error);
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    if (!categoryId) return;
    if (!organizationId) return;
    try {
      const response = await getOrganizationProducts(
        organizationId,
        categoryId
      );
      setResultProducts(response.data.value);
    } catch (error) {
      console.error('Failed to fetch organization products', error);
    }
  }, [organizationId, categoryId]);

  useEffect(() => {
    void fetchCategoryId();
  }, [fetchCategoryId]);

  useEffect(() => {
    void fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    if (productId && resultProducts.length > 0) {
      const matchingProduct = resultProducts.find(
        (product) => product.id === productId
      );
      if (matchingProduct) {
        setExhibition({ id: matchingProduct.id, name: matchingProduct.name });
        const locationPrefecture = matchingProduct.locations.find(
          (location) => location.type === ProductLocationType.PREFECTURE
        );
        if (locationPrefecture) {
          setPrefecture({
            id: locationPrefecture.id,
            name: locationPrefecture.name,
          });
        }
      }
      setIsLoading(false);
    }
  }, [productId, resultProducts]);

  useEffect(() => {
    if (isExhibition === true) {
      if (filteredProducts.length > 0) {
        setShouldDisable(false);
        return;
      }
      setShouldDisable(true);
      return;
    }

    setShouldDisable(false);
    return;
  }, [prefecture?.id, exhibition?.id, filteredProducts.length, isExhibition]);

  useEffect(() => {
    const lTree = locationsTree.flatMap((region) =>
      region.children
        ? region.children.map((option) => ({
            id: option.id,
            name: option.name,
          }))
        : []
    );
    setResultLocations(lTree);
  }, [locationsTree]);

  const handleStep1Submit = (data: InquiryStepOneFormData) => {
    const updatedCustomer = {
      ...data.customer,
      addressLine1: data.customer.addressLine1,
      prefectureName: data.customer.addressLine1,
    };

    setStepData((prevData) => ({
      ...prevData,
      customFields: {
        ...prevData.customFields,
        ...data.customFields,
        prefecture,
      },
      customer: updatedCustomer,
      items: {
        product: {
          ...exhibition,
        },
        quantity: 1,
        variantId: exhibition?.id,
      },
    }));
    onSubmit({
      ...data,
      customFields: {
        ...data.customFields,
        prefecture,
      },
      customer: updatedCustomer,
    });
  };

  if (isLoading) {
    return (
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        alignItems: 'center',
        pb: 4,
        width: '100%',
      }}
      spacing={4}
    >
      {isExhibition && (
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleStep1Submit(getValues());
          }}
          sx={{
            backgroundColor: theme.palette.neutral.blueLight,
            left: '50%',
            padding: '40px',
            right: '50%',
            width: '100vw',
          }}
        >
          <Box sx={{ margin: '0 auto', maxWidth: '800px', px: 6 }}>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.neutral.white, mb: 4 }}
            >
              1. 見学予定地をお選びください
            </Typography>
            <FormControl fullWidth sx={{ mb: 4 }}>
              {FormLabelComponent('見学する住宅展示場の都道府県', true)}
              <Select
                labelId="prefecture-label"
                value={prefecture?.id}
                onChange={(e) => {
                  const selectedPrefecture = resultLocations.find(
                    (location) => location.id === e.target.value
                  );
                  if (selectedPrefecture) {
                    setPrefecture({
                      id: selectedPrefecture.id,
                      name: selectedPrefecture.name,
                    });
                  }
                }}
                displayEmpty
                sx={{
                  '.MuiSvgIcon-root': {
                    color: theme.typography.body1,
                  },
                  backgroundColor: theme.palette.neutral.white,
                  color: theme.typography.body1,
                }}
              >
                <MenuItem value="" disabled>
                  <em>選択してください</em>
                </MenuItem>
                {resultLocations.map(
                  (location: { id: string; name: string }) => (
                    <MenuItem key={location.id} value={location.id}>
                      {location.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 4 }}>
              {FormLabelComponent('見学する住宅展示場', true)}
              <Select
                labelId="exhibition-label"
                value={exhibition?.id}
                onChange={(e) => {
                  const selectedProduct = resultProducts.find(
                    (product) => product.id === e.target.value
                  );
                  if (selectedProduct) {
                    setExhibition({
                      id: selectedProduct.id,
                      name: selectedProduct.name,
                    });
                  }
                }}
                displayEmpty
                sx={{
                  '.MuiSvgIcon-root': {
                    color: theme.typography.body1,
                  },
                  backgroundColor: theme.palette.neutral.white,
                  color: theme.typography.body1,
                }}
              >
                <MenuItem value="" disabled>
                  <em>選択してください</em>
                </MenuItem>
                {filteredProducts.map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.neutral.white, mb: 4 }}
            >
              ※ 見学予定地を選択すると下記フォームが入力可能になります。
            </Typography>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          maxWidth: '800px',
          position: 'relative',
          width: '100%',
        }}
      >
        {isDisabled && (
          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              borderRadius: '8px',
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
              zIndex: 1,
            }}
          />
        )}

        <FormProvider<InquiryStepOneFormData> {...form}>
          <Stack
            component="form"
            onSubmit={handleSubmit(handleStep1Submit)}
            sx={{
              backgroundColor: theme.palette.neutral.white,
              maxWidth: '800px',
              mb: 20,
              padding: '40px',
              width: '100%',
            }}
          >
            <Typography variant="h6" sx={{ mb: 4 }}>
              2. あなたについて教えてください
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
              {FormLabelComponent('名前', true)}
              <Controller
                name="customer.name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    placeholder="山田太郎"
                    disabled={shouldDisable}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
              {FormLabelComponent('フリガナ', true)}
              <Controller
                name="customer.nameKana"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    placeholder="ヤマダタロウ"
                    disabled={shouldDisable}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
              {FormLabelComponent('年齢', true)}
              <Stack>
                <Controller
                  name="customer.age"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Stack direction="row" alignItems="center">
                        <TextField
                          {...field}
                          type="number"
                          error={!!error}
                          placeholder="30"
                          sx={{ width: '100px' }}
                          disabled={shouldDisable}
                        />
                        <Typography sx={{ ml: 1 }}>歳</Typography>
                      </Stack>
                      {error && (
                        <FormHelperText error>{error.message}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </Stack>
            </FormControl>

            <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
              {FormLabelComponent('電話番号', true)}
              <Controller
                name="customer.phone"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="text"
                    error={!!error}
                    helperText={error?.message}
                    placeholder="09012345678"
                    disabled={shouldDisable}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
              {FormLabelComponent('メールアドレス', true)}
              <Controller
                name="customer.email"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="email"
                    error={!!error}
                    helperText={error?.message}
                    placeholder="email@example.com"
                    disabled={shouldDisable}
                  />
                )}
              />
              <Typography
                color={theme.palette.neutral.greyDark}
                sx={{ ml: 1, mt: 1 }}
              >
                ※記入したメールアドレスに申し込み完了メールが届きます。
              </Typography>
            </FormControl>

            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              {FormLabelComponent('住所', true)}
              <Stack spacing={2} sx={{ mb: 1 }}>
                <LocationsWithPostalCode />
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ mb: 1 }}
              >
                <Typography
                  color="textPrimary"
                  variant="body2"
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  町名
                </Typography>
                <Controller
                  name="customer.addressLine3"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder="金町"
                      error={!!error}
                      helperText={error?.message}
                      sx={{ flex: 1 }}
                      disabled={shouldDisable}
                    />
                  )}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ mb: 1 }}
              >
                <Typography
                  color="textPrimary"
                  variant="body2"
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  番地
                </Typography>
                <Controller
                  name="customFields.addressLine4"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder="1-2-3"
                      error={!!error}
                      helperText={error?.message}
                      sx={{ flex: 1 }}
                      disabled={shouldDisable}
                    />
                  )}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ mb: 2 }}
              >
                <Typography
                  color="textPrimary"
                  variant="body2"
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  建物名称・部屋番号
                </Typography>
                <Controller
                  name="customFields.addressLine5"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder=""
                      error={!!error}
                      helperText={error?.message}
                      sx={{ flex: 1 }}
                      disabled={shouldDisable}
                    />
                  )}
                />
              </Stack>
            </FormControl>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 8 }}
            >
              次へ
            </Button>
          </Stack>
        </FormProvider>
      </Box>
    </Stack>
  );
}
