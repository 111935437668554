import { Button, Container, Grid, Stack, useTheme } from '@mui/material';
import React, { ReactElement, useState } from 'react';

import { PropertyCard, ExhibitionCard } from '@app/components/Product/Card';
import { imageAssets } from '@app/theme';
import { Product } from '@app/types/catalog';

interface ProductCardListProps {
  initialVisibleItems?: number;
  isProperty?: boolean;
  products: Product[];
}

export function ProductCardList({
  products,
  initialVisibleItems = 6,
  isProperty = false,
}: ProductCardListProps): ReactElement {
  const theme = useTheme();
  const [visibleItems, setVisibleItems] = useState(initialVisibleItems);

  const loadMore = () => {
    setVisibleItems((prev) => prev + 6);
  };

  return (
    <Container sx={{ mb: 12 }}>
      <Stack
        spacing={4}
        width={1}
        sx={{ mt: 5 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid container spacing={1} justifyContent="flex-start">
          {products.slice(0, visibleItems).map((data, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              key={index}
              display="flex"
              justifyContent="center"
              sx={{ mb: 5 }}
            >
              {isProperty ? (
                <PropertyCard
                  imageSrc={data.images[0]?.url || imageAssets.productDefault}
                  title={data.name}
                  address={
                    data.addressLine1 + data.addressLine2 + data.addressLine3 ||
                    ''
                  }
                  minPrice={data.customFields?.minPrice ?? 0}
                  productId={data.id}
                />
              ) : (
                <ExhibitionCard
                  imageSrc={data.images[0]?.url || imageAssets.productDefault}
                  title={data.name}
                  address={
                    data.addressLine1 + data.addressLine2 + data.addressLine3 ||
                    ''
                  }
                  organizationId={data.organizationId}
                />
              )}
            </Grid>
          ))}
        </Grid>
        {visibleItems < products.length && (
          <Button
            variant="outlined"
            onClick={loadMore}
            sx={{
              borderColor: theme.palette.grey[700],
              color: theme.palette.grey[700],
              px: 6,
              py: 1,
            }}
          >
            もっと見る
          </Button>
        )}
      </Stack>
    </Container>
  );
}
