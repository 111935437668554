import { Grid, Typography, Link as MuiLink, Box, Stack } from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useNavigate,
  Link,
  useSearchParams,
  useParams,
} from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { getProduct } from '@app/adapter/catalog-service';
import { createOrder } from '@app/adapter/order-service';
import { getOrganization } from '@app/adapter/organization-service';
import { Confirm } from '@app/components/Company/Inquiry/Confirm';
import { CompanyInquiryStep1 } from '@app/components/Company/Inquiry/InquiryStep1';
import { CompanyInquiryStep2 } from '@app/components/Company/Inquiry/InquiryStep2';
import { ProgressStepper } from '@app/components/Order/ProgressStepper';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { InquiryStepOneState, InquiryStepTwoState } from '@app/domain/order';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import { CategoryType, Product } from '@app/types/catalog';
import {
  InquiryStepOneFormData,
  InquiryStepTwoFormData,
  OrderCreation,
} from '@app/types/order';
import { InquiryType, Organization } from '@app/types/organization';
import { isError } from '@app/utils/error';

export enum OrderStep {
  COMPLETE = 'complete',
  IN_REVIEW = 'inReview',
  STEP1 = 'step1',
  STEP2 = 'step2',
}

export const formatStepTwoData = (data: InquiryStepTwoFormData) => {
  const customForms = [];

  for (const key in data) {
    if (!key.includes('その他')) {
      const answers = [String(data[key])];
      const otherKey = key + ' その他';
      if (data[otherKey]) {
        answers.push(String(data[otherKey]));
      }
      customForms.push({
        answer: answers,
        question: key,
      });
    }
  }

  return customForms;
};

export function Register(): ReactElement {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const { organizationId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVariant, setIsLoadingVariant] = useState(false);

  const [step1Data, setSte1pData] = useRecoilState(InquiryStepOneState);
  const [step2Data] = useRecoilState(InquiryStepTwoState);
  const resetValueStep2Data = useResetRecoilState(InquiryStepTwoState);

  const [step, setStep] = useState<OrderStep>(OrderStep.STEP1);
  const [searchParams] = useSearchParams();
  const category = searchParams.get('category');
  const productId = searchParams.get('productId');
  const [productInfo, setProductInfo] = useState<{
    productId: string;
    quantity: number;
    variantId: string;
  } | null>(null);

  const [organization, setOrganization] = useState<Organization | null>(null);
  const [product, setProduct] = useState<Product | null>(null);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const isExhibition = useMemo(() => {
    return category && category === CategoryType.EXHIBITION;
  }, [category]);

  const handleSubmitStep1Form = useCallback(
    (data: InquiryStepOneFormData) => {
      setSte1pData(data);
      setStep(OrderStep.STEP2);
      scrollToTop();
    },
    [setSte1pData]
  );

  const handleSubmitStep2Form = useCallback(
    (data: { productId: string; quantity: number; variantId: string }) => {
      setProductInfo(data);
      setStep(OrderStep.IN_REVIEW);
      scrollToTop();
    },
    []
  );

  const handleSubmitRegistrationForm = useCallback(async () => {
    if (!productInfo || !product?.organizationId) return;
    const payload: OrderCreation = {
      customFields: {
        addressLine4: step1Data.customFields.addressLine4,
        addressLine5: step1Data.customFields.addressLine5,
        customForms: formatStepTwoData(step2Data),
        orderType:
          category === CategoryType.EXHIBITION
            ? InquiryType.ExhibitionRequestDoc
            : InquiryType.PropertyRequestDoc,
        postalCode: step1Data.customFields.postalCode,
      },
      customer: {
        ...step1Data.customer,
      },
      items: [productInfo],
    };

    try {
      setIsLoading(true);
      await createOrder(product.organizationId, payload);
      resetValueStep2Data();
      navigate(
        `/companies/${organizationId}/inquiry/create/complete?productId=${productId}`
      );
    } catch (error) {
      if (isError(error)) {
        setSnackbar(true, error.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    category,
    navigate,
    organizationId,
    product?.organizationId,
    productId,
    productInfo,
    resetValueStep2Data,
    setSnackbar,
    step1Data.customFields.addressLine4,
    step1Data.customFields.addressLine5,
    step1Data.customFields.postalCode,
    step1Data.customer,
    step2Data,
  ]);

  const handleBackToStep1 = () => {
    setStep(OrderStep.STEP1);
  };

  const handleBackToStep2 = () => {
    setStep(OrderStep.STEP2);
  };

  const fetchOrganizationAndProductVariant = useCallback(async () => {
    if (!organizationId || !productId) return;
    try {
      setIsLoadingVariant(true);
      const productResponse = await getProduct(productId);
      setProduct(productResponse.data);

      if (!productResponse.data.organization) {
        return;
      }

      const organization = productResponse.data.organization as Organization;
      const orgResponse = await getOrganization(organization.parentId);
      setOrganization(orgResponse.data);
    } catch (error) {
      if (isError(error)) {
        setSnackbar(true, error.message);
      }
    } finally {
      setIsLoadingVariant(false);
    }
  }, [organizationId, productId, setSnackbar]);

  useEffect(() => {
    void fetchOrganizationAndProductVariant();
  }, [fetchOrganizationAndProductVariant]);

  return (
    <>
      <HeadBlock />
      <Grid
        container
        direction="column"
        alignItems="center"
        textAlign="center"
        sx={{ mt: 10 }}
      >
        {organization && (
          <Typography variant="h3" fontWeight="bold">
            {organization.name}
          </Typography>
        )}

        <Typography variant="h3" fontWeight="bold">
          資料請求
        </Typography>
        <Grid sx={{ my: 3 }}>
          <Typography color="textSecondary">
            対象ハウスメーカーのカタログをまとめて一括請求（無料）できます。
          </Typography>
          <Grid sx={{ mb: 3, mt: 6 }}>
            <MuiLink
              component={Link}
              to={
                (isExhibition ? '/exhibitions' : '/properties') +
                `/${productId}/inquiry/create`
              }
              variant="body1"
              onClick={resetValueStep2Data}
            >
              見学予約はこちらから
            </MuiLink>
          </Grid>
        </Grid>
        <ProgressStepper
          activeStep={
            step === OrderStep.STEP1
              ? 0
              : step === OrderStep.STEP2
              ? 1
              : step === OrderStep.IN_REVIEW
              ? 2
              : step === OrderStep.COMPLETE
              ? 4
              : 0
          }
        />
      </Grid>
      <Stack
        sx={{
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: 9, py: 4, width: '100%' }}>
          <Box sx={{ p: '0 !important' }}>
            {step === OrderStep.STEP1 && (
              <CompanyInquiryStep1
                onSubmit={handleSubmitStep1Form}
                goToStep2={() => setStep(OrderStep.STEP2)}
                defaultValues={step1Data}
              />
            )}
            {step === OrderStep.STEP2 && (
              <CompanyInquiryStep2
                onSubmit={handleSubmitStep2Form}
                defaultValues={step2Data}
                onBack={handleBackToStep1}
                product={product}
                organization={organization}
                isLoadingVariant={isLoadingVariant}
              />
            )}
            {step === OrderStep.IN_REVIEW && (
              <Confirm
                onSubmit={handleSubmitRegistrationForm}
                onBackToStep1={handleBackToStep1}
                onBackToStep2={handleBackToStep2}
                isLoading={isLoading}
              />
            )}
          </Box>
        </Box>
      </Stack>
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
    </>
  );
}
