import { LoadingButton } from '@mui/lab';
import {
  Box,
  Stack,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { AccordionSection } from '@app/components/Order/AccordionSection';
import { InquiryStepOneState, InquiryStepTwoState } from '@app/domain/order';
import { PRIVACY_POLICY_LINK } from '@app/static/constants';
import { theme } from '@app/theme';
import {
  InquiryStepOneFormData,
  InquiryStepTwoFormData,
} from '@app/types/order';

interface DetailRowProps {
  label: string;
  value: string;
}

function DetailRow({ label, value }: DetailRowProps) {
  return (
    <Stack spacing={1} sx={{ mb: 4 }}>
      <Typography variant="body1">{label}</Typography>
      <Typography>{value ?? '-'}</Typography>
    </Stack>
  );
}

interface ConfirmProps {
  isExhibition?: boolean;
  isLoading: boolean;
  onBackToStep1: () => void;
  onBackToStep2: () => void;
  onSubmit: (data: InquiryStepOneFormData & InquiryStepTwoFormData) => void;
}

export function Confirm({
  onSubmit,
  onBackToStep1,
  onBackToStep2,
  isLoading,
  isExhibition,
}: ConfirmProps): ReactElement {
  const step1Data = useRecoilValue(InquiryStepOneState);
  const step2Data = useRecoilValue(InquiryStepTwoState);

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const combinedData = {
    ...step1Data,
    ...step2Data,
  } as InquiryStepOneFormData & InquiryStepTwoFormData;

  const handleBackToStep1 = () => {
    onBackToStep1();
  };

  const handleBackToStep2 = () => {
    onBackToStep2();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(event.target.checked);
  };

  return (
    <Stack
      spacing={5}
      alignItems="center"
      sx={{
        margin: '0 auto',
        width: '100%',
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.neutral.redLight,
          border: `1px solid ${theme.palette.error.main}`,
          borderRadius: 1,
          color: theme.palette.error.main,
          margin: '0 auto',
          maxWidth: '800px',
          padding: 3,
          width: '100%',
        }}
      >
        <Typography variant="body1">予約はまだ完了していません。</Typography>
        <Typography variant="body1">
          内容をご確認の上「予約する」ボタンを押してください。
        </Typography>
      </Box>
      <Box
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: 1,
          margin: '0 auto',
          maxWidth: '800px',
          p: 6,
          width: '100%',
        }}
      >
        {isExhibition && (
          <>
            <DetailRow
              label="来場したい住宅展示場の都道府県"
              value={step1Data.customFields.prefecture.name}
            />
            <DetailRow
              label="見学する住宅展示場"
              value={step1Data.items.product.name}
            />
          </>
        )}
        <DetailRow label="名前" value={step1Data.customer.name} />
        <DetailRow label="フリガナ" value={step1Data.customer.nameKana} />
        <DetailRow label="年齢" value={`${step1Data.customer.age}歳`} />
        <DetailRow label="電話番号" value={step1Data.customer.phone} />
        <DetailRow label="メールアドレス" value={step1Data.customer.email} />
        <Stack spacing={1} sx={{ mb: 4 }}>
          <Typography variant="body1">住所</Typography>
          <Typography>〒{step1Data.customFields.postalCode}</Typography>
          <Stack direction="row">
            <Typography>{step1Data.customer.addressLine1}</Typography>
            <Typography>{step1Data.customer.addressLine2}</Typography>
            <Typography>{step1Data.customer.addressLine3}</Typography>
            <Typography>{step1Data.customFields.addressLine4}</Typography>
            <Typography>{step1Data.customFields.addressLine5}</Typography>
          </Stack>
        </Stack>
        <Button onClick={handleBackToStep1}>← 修正する</Button>
      </Box>
      <Box
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: 1,
          margin: '0 auto',
          maxWidth: '800px',
          p: 6,
          width: '100%',
        }}
      >
        {Object.keys(step2Data).map((key) => (
          <DetailRow
            key={key}
            label={key}
            value={String(step2Data[key] ?? '-')}
          />
        ))}
        <Button onClick={handleBackToStep2}>← 修正する</Button>
      </Box>

      <Stack alignItems="center" sx={{ width: '100%' }}>
        <Box
          sx={{
            backgroundColor: theme.palette.neutral.silver,
            border: `1px solid ${theme.palette.neutral.greyDark}`,
            borderRadius: 1,
            color: theme.palette.text.primary,
            margin: '0 auto',
            maxWidth: '800px',
            padding: 3,
            width: '100%',
          }}
        >
          <Typography variant="body1">
            【携帯メールアドレスのドメイン指定受信に関するお願い】
          </Typography>
          <Typography variant="body1">
            メールアドレスを登録された方には、自動で確認メールを送信しております。メールアドレスの誤入力、携帯電話のドメイン指定受信の設定などにより確認メールを受信出来ない場合がございます。迷惑メールなどに入っている場合もございますので、再度受信設定をご確認ください。確認メールが届かない場合は、当社からの返信を受信出来ない可能性がございますので、お手数ですが、メール以外の連絡方法を追記の上、再度お問い合わせいただきますようお願い致します。
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            【大型連休前のお申込みについて】
          </Typography>
          <Typography variant="body1">
            年末年始、お盆休み、その他大型連休前にお申込みを頂いた場合、見学予約日程の確定までに少々お時間を頂くことがございます。
          </Typography>
        </Box>

        <AccordionSection />

        <FormControlLabel
          control={
            <Checkbox
              checked={isCheckboxChecked}
              onChange={handleCheckboxChange}
            />
          }
          sx={{ mt: 3 }}
          label={
            <>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={PRIVACY_POLICY_LINK}
                style={{ textDecoration: 'none' }}
              >
                <Typography component="span" color="primary" variant="body2">
                  プライバシーポリシー
                </Typography>
              </Link>
              に同意しました
            </>
          }
        />
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          sx={{ mt: 5 }}
        >
          データを送信される際の情報はSSL暗号化通信により保護されますので安心してご利用ください。
        </Typography>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        sx={{ maxWidth: '800px', pb: 20, pt: 10, width: '100%' }}
      >
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={handleBackToStep2}
          sx={{ flex: 1 }}
        >
          戻る
        </Button>
        <LoadingButton
          type="button"
          variant="contained"
          color="primary"
          onClick={() => onSubmit(combinedData)}
          sx={{ flex: 1 }}
          disabled={!isCheckboxChecked}
          loading={isLoading}
        >
          予約する
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
