import { Grid, Typography, Box, Stack, Divider } from '@mui/material';
import React, { ReactElement } from 'react';

import { theme } from '@app/theme';
import { Product } from '@app/types/catalog';
import { formatPrice } from '@app/utils/format';

interface PropertySalesInfoProps {
  product: Product;
}

export function PropertySalesInfo({
  product,
}: PropertySalesInfoProps): ReactElement {
  return (
    <Box sx={{ width: 1 }}>
      <Divider sx={{ mt: 2 }} />
      <Grid container direction="column">
        <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3 }}>
          販売概要
        </Typography>
        {product.customFields.minPrice && product.customFields.maxPrice && (
          <Grid item>
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.neutral.blueLight,
                fontWeight: 'bold',
                mt: 3,
              }}
            >
              {formatPrice(product.customFields.minPrice)}万円〜
              {formatPrice(product.customFields.maxPrice)}万円
            </Typography>
          </Grid>
        )}
      </Grid>

      <Box
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: 1,
          mt: 2,
          padding: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                交通
              </Typography>
              <Typography variant="body2">
                {product.customFields.access
                  ? product.customFields.access
                  : '-'}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                構造/階数
              </Typography>
              <Typography variant="body2">
                {product.customFields.buildingSystem
                  ? product.customFields.buildingSystem
                  : '-'}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                住所
              </Typography>
              <Typography variant="body2">
                {product.addressLine1 &&
                product.addressLine2 &&
                product.addressLine3
                  ? `${product.addressLine1} ${product.addressLine2} ${product.addressLine3}`
                  : '-'}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                販売戸数
              </Typography>
              <Typography variant="body2">
                {product.customFields.salesHouseCount
                  ? `${product.customFields.salesHouseCount}戸`
                  : '- 戸'}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ mt: 6 }} />
    </Box>
  );
}
