import {
  FormControl,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Select,
  MenuItem,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';

import { FormLabelComponent } from '@app/components/Order/FormLabelComponents';
import { convertNewLineToBr } from '@app/components/Shared/Helpers';
import { theme } from '@app/theme';
import {
  InquiryStepTwoQuestion,
  InquiryStepTwoFormData,
  FormType,
} from '@app/types/order';

interface ExhibitionFormFieldsProps {
  control: Control<InquiryStepTwoFormData>;
  errors: FieldErrors<InquiryStepTwoFormData>;
  isDisabled: boolean;
  questions: InquiryStepTwoQuestion[];
}

export function ExhibitionFormFields({
  questions,
  control,
  errors,
  isDisabled,
}: ExhibitionFormFieldsProps): ReactElement {
  return (
    <Stack spacing={5}>
      {questions.map((question, index) => (
        <FormControl fullWidth key={index} error={!!errors[question.title]}>
          {FormLabelComponent(question.title, question.required)}
          <Controller
            name={question.title}
            control={control}
            rules={{
              required: question.required
                ? `${question.title}は必須です`
                : false,
              validate: (value) => {
                if (question.required) {
                  if (Array.isArray(value)) {
                    return value.length > 0 || '無効な入力です';
                  }
                  return (
                    (typeof value === 'string' && value.trim() !== '') ||
                    '無効な入力です'
                  );
                }
                return true;
              },
            }}
            render={({ field }) => (
              <>
                {question.formType === FormType.TEXT && (
                  <>
                    <TextField
                      {...field}
                      type="text"
                      value={field.value || ''}
                      placeholder={`${question.title}を入力してください`}
                      disabled={isDisabled}
                      error={!!errors[question.title]}
                      helperText={
                        errors[question.title]
                          ? (errors[question.title] as { message: string })
                              .message
                          : ''
                      }
                    />
                    {question.otherFlg && (
                      <Controller
                        name={`${question.title} その他`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="text"
                            sx={{ mt: 1 }}
                            value={field.value || ''}
                            placeholder="その他"
                            disabled={isDisabled}
                          />
                        )}
                      />
                    )}
                    {question.subText && (
                      <Typography
                        sx={{
                          backgroundColor: theme.palette.grey[100],
                          border: `1px solid ${theme.palette.grey[400]}`,
                          borderRadius: 1,
                          fontWeight: 'bold',
                          mt: 1,
                          p: 1.5,
                        }}
                        variant="body1"
                      >
                        {convertNewLineToBr(question.subText)}
                      </Typography>
                    )}
                  </>
                )}
                {question.formType === FormType.RADIO && (
                  <>
                    <RadioGroup {...field} value={field.value || ''}>
                      {question.selections.map((option, index) => (
                        <FormControlLabel
                          key={index}
                          value={option}
                          control={<Radio />}
                          label={option}
                          disabled={isDisabled}
                        />
                      ))}
                    </RadioGroup>
                    {!!errors[question.title] && (
                      <FormHelperText error>
                        {
                          (errors[question.title] as { message: string })
                            .message
                        }
                      </FormHelperText>
                    )}
                    {question.otherFlg && (
                      <Controller
                        name={`${question.title} その他`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{ mt: 1 }}
                            type="text"
                            value={field.value || ''}
                            placeholder="その他"
                            disabled={isDisabled}
                          />
                        )}
                      />
                    )}
                    {question.subText && (
                      <Typography
                        sx={{
                          backgroundColor: theme.palette.grey[100],
                          border: `1px solid ${theme.palette.grey[400]}`,
                          borderRadius: 1,
                          fontWeight: 'bold',
                          mt: 1,
                          p: 1.5,
                        }}
                        variant="body1"
                      >
                        {convertNewLineToBr(question.subText)}
                      </Typography>
                    )}
                  </>
                )}
                {question.formType === FormType.CHECKBOX && (
                  <>
                    {question.selections.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Controller
                            name={question.title}
                            control={control}
                            render={({ field }) => {
                              const valueArray = Array.isArray(field.value)
                                ? field.value
                                : [];
                              return (
                                <Checkbox
                                  {...field}
                                  value={option}
                                  checked={valueArray.includes(option)}
                                  onChange={(e) => {
                                    const newValue = e.target.checked
                                      ? [...valueArray, option]
                                      : valueArray.filter(
                                          (value: string) => value !== option
                                        );
                                    field.onChange(newValue);
                                  }}
                                  disabled={isDisabled}
                                />
                              );
                            }}
                          />
                        }
                        label={option}
                      />
                    ))}
                    {!!errors[question.title] && (
                      <FormHelperText error>
                        {
                          (errors[question.title] as { message: string })
                            .message
                        }
                      </FormHelperText>
                    )}
                    {question.otherFlg && (
                      <Controller
                        name={`${question.title} その他`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{ mt: 1 }}
                            type="text"
                            value={field.value || ''}
                            placeholder="その他"
                            disabled={isDisabled}
                          />
                        )}
                      />
                    )}
                    {question.subText && (
                      <Typography
                        sx={{
                          backgroundColor: theme.palette.grey[100],
                          border: `1px solid ${theme.palette.grey[400]}`,
                          borderRadius: 1,
                          fontWeight: 'bold',
                          mt: 1,
                          p: 1.5,
                        }}
                        variant="body1"
                      >
                        {convertNewLineToBr(question.subText)}
                      </Typography>
                    )}
                  </>
                )}
                {question.formType === FormType.PULLDOWN && (
                  <>
                    <Controller
                      name={question.title}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            value={field.value || ''}
                            displayEmpty
                            disabled={isDisabled}
                            error={!!errors[question.title]}
                          >
                            <MenuItem value="">
                              <em>選択してください</em>
                            </MenuItem>
                            {question.selections.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                    {!!errors[question.title] && (
                      <FormHelperText error>
                        {
                          (errors[question.title] as { message: string })
                            .message
                        }
                      </FormHelperText>
                    )}
                    {question.otherFlg && (
                      <Controller
                        name={`${question.title} その他`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{ mt: 1 }}
                            type="text"
                            value={field.value || ''}
                            placeholder="その他"
                            disabled={isDisabled}
                          />
                        )}
                      />
                    )}
                    {question.subText && (
                      <Typography
                        sx={{
                          backgroundColor: theme.palette.grey[100],
                          border: `1px solid ${theme.palette.grey[400]}`,
                          borderRadius: 1,
                          fontWeight: 'bold',
                          mt: 1,
                          p: 1.5,
                        }}
                        variant="body1"
                      >
                        {convertNewLineToBr(question.subText)}
                      </Typography>
                    )}
                  </>
                )}
              </>
            )}
          />
        </FormControl>
      ))}
    </Stack>
  );
}
