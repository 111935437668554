import { FormType } from '@app/types/order';
import { ReviewStats } from '@app/types/review';

export enum OrganizationStatus {
  BEFORE_REVIEW = 'beforeReview',
  CONFIRMED = 'confirmed',
  IN_REVIEW = 'inReview',
  REJECTED = 'rejected',
}

export type OrganizationStatusKey =
  (typeof OrganizationStatus)[keyof typeof OrganizationStatus];

export const OrganizationType = {
  COMPANY: '010',
  PERSONAL: '011',
} as const;
export type OrganizationTypeKey =
  (typeof OrganizationType)[keyof typeof OrganizationType];

export const OrganizationAuditStatus = {
  APPROVED: 'approved',
  CHANGE_REQUESTED: 'change_requested',
  IN_REVIEW: 'in_review',
  REJECTED: 'rejected',
  WAITING_FOR_CHANGE_REVIEW: 'waiting_for_change_review',
  WAITING_FOR_REVIEW: 'waiting_for_review',
} as const;
export type OrganizationAuditStatusKey =
  (typeof OrganizationAuditStatus)[keyof typeof OrganizationAuditStatus];

export const OrganizationStructureType = {
  CHILD: 'child',
  PARENT: 'parent',
} as const;
export type OrganizationStructureTypeKey =
  (typeof OrganizationStructureType)[keyof typeof OrganizationStructureType];
export interface OrganizationImage {
  id?: string;
  type?: string;
  url: string;
}

export interface OrganizationStrength extends Partial<OrganizationImage> {
  description: string;
  image?: {
    id?: string;
    url?: string;
  };
  title: string;
}

export interface SearchResultOrganizations {
  data: Organization[];
  nextLink: string;
  scrollY?: number;
  total: number;
}

export interface Organization {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  auditStatus: OrganizationAuditStatusKey;
  branchName?: string;
  certificateImageUri?: string;
  chart?: boolean;
  children?: Organization[];
  childrenCount: number;
  createdAt: string;
  customFields?: OrganizationCustomFields;
  description?: string;
  electronicChart?: boolean;
  electronicChartMaker?: string;
  equipment?: string[];
  equipmentOther?: string;
  iconImageUri?: string;
  id: string;
  name: string;
  numberOfProjects?: string;
  ownerId: string;
  parent?: Organization;
  parentId: string;
  phoneNumber?: string;
  pictureUrl?: string;
  postalCode?: string;
  reviewStats?: ReviewStats;
  size?: string;
  status: OrganizationStatusKey;
  typeId: OrganizationTypeKey;
  updatedAt: string;
  url?: string;
}

export interface OrganizationCustomFields {
  access: string;
  address?: string;
  addressByCity?: string;
  addressLine4: string;
  buildArea: string[];
  capital?: string;
  chatContact: string;
  clinicUrl: string;
  closingDay?: string;
  closingDaySub?: string;
  compatibleConstructionMethod?: string;
  compensate?: string;
  constructionArea: string;
  constructionResult?: string;
  department: string;
  electronicChartMaker: string;
  electronicCharts: string[];
  equipmentOther: string;
  equipments: string[];
  establishment?: string;
  exhibitionRequestDocQuestions?: InquiryStepTwoQuestion[];
  exhibitionVisitorReserveQuestions?: InquiryStepTwoQuestion[];
  features?: string[];
  mainCatch?: string;
  mainImageIds?: string[];
  mainImages?: Partial<OrganizationImage>[];
  ownerName: string;
  ownerUserId: string;
  phoneNumber: string;
  phoneNumberSub?: string;
  prefectureCity?: string;
  propertyRequestDocQuestions?: InquiryStepTwoQuestion[];
  propertyVisitorReserveQuestions?: InquiryStepTwoQuestion[];
  scheduleActiveFlg?: boolean;
  scheduleWeekly?: {
    activeFlg: boolean;
    timeFrom: string;
    timeTo: string;
    week: number;
  }[];
  size?: string;
  strengths?: OrganizationStrength[];
  structureType?: OrganizationStructureTypeKey;
  subCatch?: string;
  subText?: string;
}

export interface InquiryStepTwoQuestion {
  formType: FormType;
  otherFlg: boolean;
  required: boolean;
  selections: string[];
  subText: string;
  title: string;
}

export enum InquiryType {
  ExhibitionRequestDoc = 'exhibitionRequestDoc',
  ExhibitionVisitor = 'exhibitionVisitor',
  PropertyRequestDoc = 'propertyRequestDoc',
  PropertyVisitor = 'propertyVisitor',
}

/**
 * follow
 */
export const FollowerType = {
  ORGANIZATION: 'organization',
  PRODUCT: 'product',
  USER: 'user',
} as const;
export type FollowerTypeKey = (typeof FollowerType)[keyof typeof FollowerType];

export const FollowType = {
  ORGANIZATION: 'organization',
  PRODUCT: 'product',
  USER: 'user',
} as const;
export type FollowTypeKey = (typeof FollowType)[keyof typeof FollowType];

export interface Follow {
  createdAt: Date;
  delFlg: number;
  followId: string;
  followType: FollowTypeKey;
  followerId: string;
  followerType: FollowerTypeKey;
  id: string;
  updatedAt: Date;
}

export interface FollowCreate {
  followerId: string;
  followerType: FollowerTypeKey;
}

export const StructureType = {
  CHILDREN: 'children',
  PARENT: 'parent',
} as const;
export type StructureTypeKey =
  (typeof StructureType)[keyof typeof StructureType];
