import { LoadingButton } from '@mui/lab';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';

import { ExhibitionCard, PropertyCard } from '@app/components/Product/Card';
import { LoadingSpinner } from '@app/components/Shared/LoadingSpinner';
import { imageAssets, theme } from '@app/theme';
import { Product, ProductLocation } from '@app/types/catalog';

interface ProductCardListProps {
  handleLoadMore: () => Promise<void>;
  initialVisibleItems?: number;
  isFetching: boolean;
  isLoadingMore: boolean;
  isProperty: boolean;
  prefectures: ProductLocation[];
  products: Product[];
  selectedPrefecture: string;
  setPrefecture: React.Dispatch<React.SetStateAction<string>>;
  showLoadMore: boolean;
}

export function ProductCardList({
  products,
  prefectures,
  selectedPrefecture,
  setPrefecture,
  handleLoadMore,
  showLoadMore,
  isLoadingMore,
  isProperty,
  isFetching,
}: ProductCardListProps): ReactElement {
  return (
    <Stack
      spacing={4}
      sx={{ mt: 5 }}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        px={8}
        direction="row"
        alignItems="center"
        width="100%"
        justifyContent="flex-start"
      >
        <Typography variant="h3" fontWeight="bold" py={3} mr={3}>
          {isProperty ? '分譲一覧' : '住宅展示場一覧'}
        </Typography>
        <Typography variant="body3" color="textSecondary" mr={1}>
          エリア
        </Typography>
        <Box>
          <FormControl sx={{ height: '40px', width: '140px' }}>
            <Select
              value={selectedPrefecture}
              name="prefecture"
              onChange={(event: SelectChangeEvent) => {
                setPrefecture(event.target.value);
              }}
              displayEmpty
              renderValue={(selectedValue) => {
                if (!selectedValue) {
                  return '全国';
                }
                return (
                  prefectures.find((pre) => pre.id === selectedValue)?.name ||
                  ''
                );
              }}
            >
              <MenuItem value="">全国</MenuItem>;
              {prefectures.map((pre) => (
                <MenuItem key={pre.id} value={pre.id}>
                  {pre.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        <>
          <Grid container sx={{ paddingX: '48px' }}>
            {products.length ? (
              products.map((data, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  key={index}
                  sx={{ mb: 5 }}
                  spacing={1}
                >
                  {isProperty ? (
                    <PropertyCard
                      imageSrc={
                        data.images[0]?.url || imageAssets.productDefault
                      }
                      title={data.name}
                      address={
                        data.addressLine1 +
                          data.addressLine2 +
                          data.addressLine3 || ''
                      }
                      minPrice={data.customFields?.minPrice ?? 0}
                      productId={data.id}
                    />
                  ) : (
                    <ExhibitionCard
                      imageSrc={
                        data.images[0]?.url || imageAssets.productDefault
                      }
                      title={data.name}
                      address={
                        data.addressLine1 +
                          data.addressLine2 +
                          data.addressLine3 || ''
                      }
                      productId={data.id}
                    />
                  )}
                </Grid>
              ))
            ) : (
              <Typography
                variant="body2"
                textAlign="center"
                width="100%"
                color={theme.palette.text.secondary}
              >
                {isProperty ? '物件なし' : '展示場なし'}
              </Typography>
            )}
          </Grid>
          {showLoadMore && (
            <LoadingButton
              variant="outlined"
              onClick={handleLoadMore}
              loading={isLoadingMore}
              sx={{
                borderColor: theme.palette.grey[300],
                borderRadius: 1,
                color: 'black !important',
                fontWeight: 'normal',
                px: 10,
                py: 2,
              }}
            >
              もっと見る
            </LoadingButton>
          )}
        </>
      )}
    </Stack>
  );
}
