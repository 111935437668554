import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

import { ImageCarousel } from '@app/components/Product/CarouselItem';
import { StrengthsSection } from '@app/components/Product/StrengthsSection';
import { Product } from '@app/types/catalog';

interface ProductDetailsProps {
  product: Product;
}

export function ProductDetails({ product }: ProductDetailsProps) {
  const theme = useTheme();

  return (
    <Stack spacing={3} sx={{ width: '100%' }}>
      <Typography variant="h2" fontWeight={600}>
        {product.name}
      </Typography>
      {typeof product.organization === 'object' &&
        product.organization !== null && (
          <Typography
            variant="body1"
            color={theme.palette.grey[500]}
            sx={{ mt: '5px !important' }}
          >
            {product.addressLine1 +
              product.addressLine2 +
              product.addressLine3 +
              (product.customFields?.addressLine4 || '')}
          </Typography>
        )}

      <ImageCarousel
        images={product.images.map((img) => img.url)}
        displayMode="largeImage"
        carouselWidth="980px"
      />

      <StrengthsSection
        features={product.features}
        strengthsTitle={product.additionalInformation}
        strengthsDescription={product.description}
        showReadMore={false}
      />
    </Stack>
  );
}
