const localeJP = 'ja-JP';
const currencyJP = 'JPY';
const defaultLocale = localeJP;
const defaultCurrency = currencyJP;

export function localizedPrice(price: number) {
  return new Intl.NumberFormat(defaultLocale, {
    currency: defaultCurrency,
    style: 'currency',
  }).format(price);
}

export const formatPrice = (price: number) => {
  return new Intl.NumberFormat('ja-JP').format(price);
};

export function restoreSanitizedString(str: string) {
  let tempStr = str.replace(/\\0/g, '\0');
  tempStr = tempStr.replace(/\\n/g, '\n');
  tempStr = tempStr.replace(/\\r/g, '\r');
  return tempStr;
}

export function formatBearerToken(token: string): string {
  return `Bearer ${token}`;
}

export function jsonParse(jsonStr: string) {
  return JSON.parse(jsonStr.replace(/\\+/g, ''));
}

export function getEllipsisStyle(lines: number) {
  return {
    WebkitBoxOrient: 'vertical' as const,
    WebkitLineClamp: lines,
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
}

export function convertToTsubo(areaInSquareMeters: number): number {
  const TSUBO_CONVERSION_RATE = 3.30579;
  return Math.round(areaInSquareMeters / TSUBO_CONVERSION_RATE);
}
