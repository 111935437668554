import {
  Card as MuiCard,
  CardMedia,
  CardContent,
  Typography,
  Link,
  Box,
} from '@mui/material';
import React, { ReactElement } from 'react';

import { imageAssets, theme } from '@app/theme';
import { Attachment } from '@app/types/catalog';

interface CardProps {
  address: string;
  imageSrc: string;
  isProperty?: boolean;
  minPrice?: number;
  organizationId?: string;
  productId?: string;
  title: string;
}

export function ExhibitionCard({
  imageSrc,
  title,
  address,
  organizationId,
  productId,
}: CardProps): ReactElement {
  return (
    <Link
      href={
        productId ? `/exhibitions/${productId}` : `/companies/${organizationId}`
      }
      underline="none"
    >
      <MuiCard sx={{ borderRadius: 2, boxShadow: 3, width: 350 }}>
        <CardContent>
          <Box
            sx={{
              backgroundImage: `url(${imageSrc})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              borderRadius: '8px',
              height: '200px',
              width: '95%',
            }}
          />

          <Box sx={{ paddingTop: '16px' }}>
            <Typography variant="subtitle2" component="div" fontWeight="bold">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {address}
            </Typography>
          </Box>
        </CardContent>
      </MuiCard>
    </Link>
  );
}

export function PropertyCard({
  imageSrc,
  title,
  address,
  minPrice,
  productId,
}: CardProps): ReactElement {
  return (
    <Link href={`/properties/${productId}`} underline="none">
      <MuiCard sx={{ borderRadius: 2, boxShadow: 3, width: 320 }}>
        <CardContent>
          <Box
            sx={{
              backgroundImage: `url(${imageSrc})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              borderRadius: '8px',
              height: '200px',
              width: '95%',
            }}
          />

          <Box sx={{ paddingTop: '16px' }}>
            <Typography
              variant="subtitle2"
              component="div"
              fontWeight="bold"
              color="primary"
            >
              {title}
            </Typography>
            <Typography variant="subtitle2" component="div" fontWeight="bold">
              {minPrice}万円
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {address}
            </Typography>
          </Box>
        </CardContent>
      </MuiCard>
    </Link>
  );
}

interface InquiryPropertyCardProps {
  access: string;
  imageSrc: Attachment[];
  isProperty?: boolean;
  maxPrice?: number;
  minPrice?: number;
  name: string;
}

export function InquiryPropertyCard({
  imageSrc,
  name,
  access,
  minPrice,
  maxPrice,
}: InquiryPropertyCardProps): ReactElement {
  const imageUrl =
    imageSrc && imageSrc.length > 0
      ? imageSrc[0].url
      : imageAssets.productDefault;
  const imageAlt =
    imageSrc && imageSrc.length > 0 ? imageSrc[0].type : '物件画像';

  return (
    <MuiCard
      sx={{
        border: `1px solid ${theme.palette.neutral.silver}`,
        borderRadius: 2,
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'row',
        width: 750,
      }}
    >
      <CardMedia
        component="img"
        height="200"
        image={imageUrl}
        alt={imageAlt}
        sx={{ m: 2, width: 250 }}
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography variant="subtitle2" fontWeight="bold">
          {name}
        </Typography>
        <Typography>
          {minPrice}万円〜{maxPrice}万円
        </Typography>
        <Typography color="text.secondary">{access}</Typography>
      </CardContent>
    </MuiCard>
  );
}
