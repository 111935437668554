import { isError } from 'util';

import { Box, Button, Typography, Grid } from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';

import { LoadingSpinner } from '../Shared/LoadingSpinner';

import { ProgressStepper } from '@app/components/Order/ProgressStepper';
import { getProduct } from '@app/domain/network-actions';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { theme } from '@app/theme';
import { Product } from '@app/types/catalog';

interface CompletedProps {
  isExhibition: boolean;
}

export function Completed({ isExhibition }: CompletedProps): ReactElement {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const setSnackbar = useSetSnackbar();
  const [product, setProduct] = useState<Product | null>(null);

  const fetchProduct = useCallback(async () => {
    if (!productId) return;
    try {
      setIsLoading(true);
      const result = await getProduct(productId);
      setProduct(result.data);
    } catch (e) {
      if (isError(e)) {
        console.error('fetchProduct', e.message);
      }
      setSnackbar(true, '住宅展示場が見つかりません');
    } finally {
      setIsLoading(false);
    }
  }, [productId, setSnackbar]);

  useEffect(() => {
    void fetchProduct();
    // eslint-disable-next-line
  }, []);

  const organization =
    typeof product?.organization !== 'string' ? product?.organization : null;

  const handleGoToTopPage = () => {
    navigate('/');
  };

  const handleRegister = () => {
    navigate('/register');
  };

  return (
    <Box
      sx={{
        margin: '0 auto',
        maxWidth: '800px',
        mt: 1,
        padding: 3,
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        textAlign="center"
        sx={{ mt: 10 }}
      >
        <Typography variant="h6" fontWeight="bold">
          {isExhibition ? '予約を受け付けました' : '見学の予約が完了しました'}
        </Typography>
        <Grid sx={{ my: 3 }}>
          <Typography color="textSecondary">
            {isExhibition
              ? '住宅展示場見学を予約いただきありがとうございました。'
              : '分譲見学を予約いただきありがとうございました。'}
          </Typography>
          <Typography color="textSecondary">
            ただいま、見学予約確認のメールをお送りさせていただきました。
          </Typography>
          <Typography color="textSecondary">
            もし確認メールが届かない場合はお手数ではございますが、下記メールにてお問い合わせください。
          </Typography>
        </Grid>
      </Grid>

      <ProgressStepper activeStep={4} />
      {isLoading ? (
        <LoadingSpinner sx={{ mb: 4 }} />
      ) : (
        <Box
          sx={{
            border: `1px solid ${theme.palette.neutral.silver}`,
            borderRadius: 1,
            color: theme.palette.text.primary,
            mb: 10,
            mt: 12,
            padding: 3,
            width: '100%',
          }}
        >
          <Typography fontWeight={'bold'} sx={{ mb: 3 }}>
            お問い合わせ先
          </Typography>
          <Typography>{organization?.name || '-'}</Typography>
          {product?.customFields.contactAddress && (
            <Typography>
              問い合わせ先名：{product.customFields.contactAddress}
            </Typography>
          )}
          {product?.customFields.contactPhoneNumber && (
            <Typography>
              問い合わせ先電話番号：{product.customFields.contactPhoneNumber}
            </Typography>
          )}
          {product?.customFields.contactAdditional && (
            <Typography>
              問い合わせ先補足：{product.customFields.contactAdditional}
            </Typography>
          )}
          <Typography>
            ※土日祝日のお問い合わせは、翌平日でのご対応とさせていただきます。
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          backgroundColor: theme.palette.neutral.sky,
          borderRadius: 2,
          margin: '0 auto',
          maxWidth: '800px',
          mb: 5,
          padding: 3,
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
          会員登録
        </Typography>
        <Typography sx={{ mb: 5 }}>
          会員登録をすると、メリットを記載する。一般的には履歴の管理ができる、次の申し込みの際にお客様情報の入力を省略できるようになる
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: '100%' }}
          onClick={handleRegister}
        >
          会員登録をする
        </Button>
      </Box>

      <Button
        variant="outlined"
        color="secondary"
        onClick={handleGoToTopPage}
        sx={{ my: 7, width: '100%' }}
      >
        トップページへ
      </Button>
      <Box sx={{ mb: 14, textAlign: 'center' }}>
        <Link
          to={
            isExhibition
              ? `/exhibitions/${productId}`
              : `/properties/${productId}`
          }
          style={{
            color: theme.palette.primary.main,
            textDecoration: 'none',
          }}
        >
          物件詳細ページ
        </Link>
      </Box>
    </Box>
  );
}
