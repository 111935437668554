import { Container, Typography } from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import {
  getOrganizationsByParentIds,
  getParentOrganizations,
} from '@app/adapter/organization-service';
import { HomeCarousel } from '@app/components/Home/HomeCarousel';
import { OrganizationCardList } from '@app/components/Product/OrganizationCardList';
import { ProductCardList } from '@app/components/Product/ProductCardList';
import { SearchNoResult } from '@app/components/Search/SearchNoResult';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { Loading } from '@app/components/Shared/Loading';
import {
  searchResultOrganizationsState,
  searchResultProductsState,
} from '@app/domain/catalog';
import { getProducts } from '@app/domain/network-actions';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import { theme } from '@app/theme';
import { CategoryType, CategoryTypeKey } from '@app/types/catalog';
import { LoadableState } from '@app/types/common';

export function Home(): ReactElement {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();
  const location = useLocation();
  const [resultProducts, setResultProducts] = useRecoilState(
    searchResultProductsState
  );
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );
  const [searchType, setSearchType] = useState<CategoryTypeKey>(
    CategoryType.EXHIBITION
  );
  const setSnackbar = useSetSnackbar();
  const [organizations, setOrganizations] = useRecoilState(
    searchResultOrganizationsState
  );

  const getSearchParams = useCallback(() => {
    const params = new URLSearchParams(location.search);
    const organizationId = params.get('organizationId');
    const childrenIds = params.get('childrenIds')?.split(',') || [];
    return {
      categoryIds: params.get('category')
        ? [params.get('category') as string]
        : [],
      childrenIds,
      filter: organizationId ? `parentId eq '${organizationId}'` : undefined,
      locationIds: params.get('locations')
        ? [params.get('locations') as string]
        : [],
      organizationId: organizationId ? [organizationId as string] : [],
    };
  }, [location.search]);

  const updateUrlWithChildrenIds = useCallback(
    (childrenIds: string[]) => {
      const params = new URLSearchParams(location.search);
      if (childrenIds.length > 0) {
        params.set('childrenIds', childrenIds.join(','));
      } else {
        params.delete('childrenIds');
      }
      navigate(`?${params.toString()}`, { replace: true });
    },
    [location.search, navigate]
  );

  const fetchProducts = useCallback(
    async (nextLink?: string) => {
      if (loadable === LoadableState.LOADING) return;
      setLoadable(LoadableState.LOADING);
      try {
        const searchParams = getSearchParams();

        let childrenIds: string[] = [];
        if (searchParams.organizationId.length > 0) {
          const parentOrganizations = await getOrganizationsByParentIds(
            searchParams.organizationId
          );
          childrenIds =
            parentOrganizations?.data?.value?.map(
              (org: { id: string }) => org.id
            ) || [];
          updateUrlWithChildrenIds(childrenIds);
        }

        const options = {
          nextLink,
          ...searchParams,
          childrenIds: childrenIds.length > 0 ? childrenIds : undefined,
          expand: 'images,organization,category,locations',
          limit: 10,
        };

        if (searchParams.organizationId.length > 0) {
          options.organizationId = searchParams.organizationId;
        }

        if (searchType === CategoryType.EXHIBITION) {
          const organizationResult = await getParentOrganizations(options);
          setOrganizations({
            data: nextLink
              ? [...organizations.data, ...organizationResult.data.value]
              : organizationResult.data.value,
            nextLink: organizationResult.data['@nextLink'],
            total: organizationResult.data.total,
          });
        } else {
          const productResult = await getProducts(options);
          setResultProducts((prevState) => ({
            data: nextLink
              ? [...prevState.data, ...productResult.data.value]
              : productResult.data.value,
            nextLink: productResult.data['@nextLink'],
            total: productResult.data.total,
          }));
        }
        setLoadable(LoadableState.HAS_VALUE);
      } catch (error) {
        setLoadable(LoadableState.HAS_ERROR);
        setSnackbar(true, 'データの取得に失敗しました');
      }
    },
    [
      loadable,
      setSnackbar,
      setResultProducts,
      getSearchParams,
      organizations.data,
      setOrganizations,
      searchType,
      updateUrlWithChildrenIds,
    ]
  );

  useEffect(() => {
    setOrganizations({
      data: [],
      nextLink: '',
      scrollY: 0,
      total: 0,
    });
    setResultProducts({
      data: [],
      nextLink: '',
      scrollY: 0,
      total: 0,
    });
    void fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const title =
    searchType === CategoryType.EXHIBITION ? '新着住宅展示場' : '新着分譲地';

  return (
    <>
      <HeadBlock />
      <HomeCarousel setSearchType={setSearchType} />
      <Container
        maxWidth="lg"
        disableGutters={!isDesktop}
        sx={{ pb: '16px!important' }}
      >
        <Typography
          sx={{
            color: theme.palette.neutral.blueLight,
            fontSize: '14px',
            pt: 6,
            textAlign: 'center',
          }}
        >
          おすすめ情報
        </Typography>
        <Typography
          sx={{
            color: 'black',
            fontSize: '1.25rem',
            fontWeight: 'bold',
            pt: 2,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      </Container>
      {loadable === LoadableState.LOADING && <Loading />}
      {loadable === LoadableState.HAS_VALUE &&
      ((searchType === CategoryType.PROPERTY &&
        resultProducts.data.length === 0) ||
        (searchType === CategoryType.EXHIBITION &&
          organizations.data.length === 0)) ? (
        <SearchNoResult />
      ) : searchType === CategoryType.EXHIBITION ? (
        <OrganizationCardList
          organizations={organizations.data}
          initialVisibleItems={6}
        />
      ) : (
        <ProductCardList
          products={resultProducts.data}
          initialVisibleItems={6}
          isProperty
        />
      )}
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} shouldShowMenuItems={false} />
    </>
  );
}
