import { Button, Container, Grid, Stack, useTheme } from '@mui/material';
import React, { ReactElement, useState } from 'react';

import { ExhibitionCard } from '@app/components/Product/Card';
import { imageAssets } from '@app/theme';
import { Organization } from '@app/types/organization';

interface OrganizationCardListProps {
  initialVisibleItems?: number;
  organizations: Organization[];
}

export function OrganizationCardList({
  organizations,
  initialVisibleItems = 6,
}: OrganizationCardListProps): ReactElement {
  const theme = useTheme();
  const [visibleItems, setVisibleItems] = useState(initialVisibleItems);

  const loadMore = () => {
    setVisibleItems((prev) => prev + 6);
  };

  return (
    <Container sx={{ mb: 12 }}>
      <Stack
        spacing={4}
        width={1}
        sx={{ mt: 5 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid container spacing={1} justifyContent="flex-start">
          {organizations.slice(0, visibleItems).map((data, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              key={index}
              display="flex"
              justifyContent="center"
              sx={{ mb: 5 }}
            >
              <ExhibitionCard
                imageSrc={
                  data?.customFields?.mainImages?.[0]?.url ||
                  imageAssets.productDefault
                }
                title={data.name}
                address={data?.customFields?.address || ''}
                organizationId={data.id}
              />
            </Grid>
          ))}
        </Grid>
        {visibleItems < organizations.length && (
          <Button
            variant="outlined"
            onClick={loadMore}
            sx={{
              borderColor: theme.palette.grey[700],
              color: theme.palette.grey[700],
              px: 6,
              py: 1,
            }}
          >
            もっと見る
          </Button>
        )}
      </Stack>
    </Container>
  );
}
